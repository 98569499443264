import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../../components/Seo'
import BigTitle from '../../components/BigTitle'
import DefaultSection from '../../components/DefaultSection'
import Schedule from '../../components/Schedule'
import ContactSection from '../../components/ContactSection'
import Card from '../../components/Card'
import { months } from '../../common/dateFunctions'

const Page = ({ data }) => {

  const now = new Date()
  const schedules = data.allTermineJson.edges
    .filter(scheduleNode => new Date(scheduleNode.schedule.endDate) >= now)
    .map(scheduleNode => scheduleNode.schedule)

  return (
    <React.Fragment>
      <Seo title="Termine"
           description="Bleiben Sie auf dem Laufenden und verpassen Sie keinen unserer Vorträge und Workshops."
      />
      <BigTitle title="Termine" className="mask-black" imageRelativePath={'Titelbild_Architektur.jpg'}
                textInverted={true} menuInverted={true} logoInverted={true}>
        Bleiben Sie auf dem Laufenden und verpassen Sie keinen unserer Vorträge und Workshops.
      </BigTitle>
      <DefaultSection>
        {schedules && schedules.length
          ? schedules.map(schedule => (<div className="mb-5" key={schedule.id}><Schedule schedule={schedule} /></div>))
          : (
            <div className="my-5 py-5">
              <Card>
                <div className="text-center text-md-left">
                  <p className="mt-5">
                    Gerade planen wir neue Termine für die Zeit ab {months[now.getMonth()] + ' ' + now.getFullYear()}.
                    Diese werden wir in Kürze auf dieser Seite veröffentlichen.
                  </p>
                </div>
              </Card>
            </div>
          )
        }
      </DefaultSection>
      <ContactSection className="bg-level-1" />
    </React.Fragment>
  )
}

export default Page

export const query = graphql`
    {
        allTermineJson(sort: {fields: startDate}) {
            edges {
                schedule: node {
                    id
                    title
                    category
                    labels
                    descriptionShort
                    startDate
                    endDate
                    personKeys
                    locationKey
                    linkDetails
                    linkBooking
                    price
                    capacity
                }
            }
        }
    }
`
